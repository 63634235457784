import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#9578FF',
      main: '#5F41CC',
      dark: '#402E7D',
      contrastText: '#fff'
    },
    secondary: {
      light: '#6BFFCD',
      main: '#2ED1B6',
      dark: '#007D74',
      contrastText: '#fff'
    },
    grey: {
      light: '#aaa',
      main: '#777',
      dark: '#333',
      contrastText: '#fff'
    },
    brandPastel: {
      one: '#FFB0B0',
      two: '#FFD4AD',
      three: '#FFEEA3',
      four: '#CCFFA3',
      five: '#B0FFCF',
      six: '#A8FFFC',
      seven: '#ADDFFF',
      eight: '#C7B8FF',
      nine: '#E9B6FF',
      ten: '#FFB3D7'
    },
    brandLight: {
      one: '#FF7373',
      two: '#FFA95E',
      three: '#FFF763',
      four: '#9FF062',
      five: '#00DB99',
      six: '#6BF3FF',
      seven: '#4DB8FF',
      eight: '#9578FF',
      nine: '#DA6BF9',
      ten: '#FF63AE'
    },
    brand: {
      one: '#CF4242',
      two: '#D9853B',
      three: '#EBD926',
      four: '#4DB531',
      five: '#00AB97',
      six: '#3DC2D1',
      seven: '#1877B8',
      eight: '#5F41CC',
      nine: '#9C30CA',
      ten: '#D63280'
    },
    brandDark: {
      one: '#942525',
      two: '#A34B00',
      three: '#C7A643',
      four: '#008213',
      five: '#008071',
      six: '#006D7D',
      seven: '#004B7D',
      eight: '#402E7D',
      nine: '#7E208F',
      ten: '#8F144F'
    },
    one_two:{
      start: 'linear-gradient(-45deg, #FF737305, #FFAD6605)',
      mid: 'linear-gradient(-45deg, #FF737320, #FFAD6620)',
      end: 'linear-gradient(-45deg, #FF737380, #FFAD6680)',
      full: 'linear-gradient(-45deg, #FF7373, #FFAD66)',
    },
    gradient1:{
      start: 'linear-gradient(-45deg, #0f00f005, #ff0ff005)',
      mid: 'linear-gradient(-45deg, #0f00f020, #ff0ff020)',
      end: 'linear-gradient(-45deg, #0f00f080, #ff0ff080)',
    },
    gradient3:{
      start: 'linear-gradient(-45deg, #B4FF7A15, #5CFF9B15, #6BD9FF15)',
      mid: 'linear-gradient(-45deg, #B4FF7A40, #5CFF9B40, #6BD9FF40)',
      end: 'linear-gradient(-45deg, #B4FF7A80, #5CFF9B80, #6BD9FF80)',
    },
    gradient2:{
      start: 'linear-gradient(-45deg, #9578FF15, #FF63AE15)',
      mid: 'linear-gradient(-45deg, #9578FF40, #FF63AE40)',
      end: 'linear-gradient(-45deg, #9578FF80, #FF63AE80)',
    },
    tertiary: {
      main: '#F2B16B',
      dark: '#CC8229',
      light: '#E5B076',
      contrastText: '#fff'
    },
    selected: {
      light: '#71CEDB',
      main: '#3A80AF',
      dark: '#00557F',
      contrastText: '#fff'
    },
    primarySub: {
      main: '#39B58A',
      dark: '#6FDD9B',
      contrastText: '#fff'
    },
    course:{
      main: '#008071',
      dark: '',
      contrastText: '#fff'
    },
    assignment:{
      light: '#00DB99',
      main: '#00AB97',
      dark: '#008071',
      contrastText: '#fff'
    },
    opinion:{
      pastel: '#C7B8FF',
      light: '#9578FF',
      main: '#5F41CC',
      dark: '#402E7D',
      contrastText: '#fff'
    },
    response:{
      pastel: '#E9B6FF',
      light: '#DA6BF9',
      main: '#9C30CA',
      dark: '#7E208F',
      contrastText: '#fff'
    },
    reply:{
      pastel: '#FFB3D7',
      light: '#FF63AE',
      main: '#D63280',
      dark: '#8F144F',
      contrastText: '#fff'
    },
    accent: {
      yellow: '#E5FF00',
      green: '#C1FF5C'
    },
    green: {
      dark: '#008071',
      mid: '#39B58A',
      light: '#6FDD9B'
    },
    tone: {
      main: '#dddddddd',
      dark: '#aaaaaaaa',
      light: '#eeeeeeee'
    },
    dark: {
      main: '#000',
      dark: '#000444',
      light: '#eee',
      contrastText: '#fff'
    },
    light: {
      main: '#fff'
    },
    background: {
      main: '#F6F6F6',
      nav: '#FFFFFF',
      side: '#FFFFFF',
      light: '#FFFFFF'
    }
  },
  palette2:{
    primary:{
      main: '#00A6A6',
      dark: '#00A6A6',
      light: '#00A6A6'
    },
    secondary:{
      main: '#F78B1E',
      dark: '#F46221',
      light: '#F2B666'
    },
    alt:{
      main: '#7AE5CA',
      dark: '',
      light: '#C7FFF0'
    },
    verify:{
      main: '#159B78',
      dark: '#057A69',
      light: '#40C69F'
    },
    blank:{
      main:'#FFFFFF',
      dark:'#EEEEEE'
    },
    background: {
      main: '#FFFFFF',
      secondary: '#F7F7F7',
      dark: '#03032E'
    },
  },
  typography: {
    fontFamily:'Inter',
    fontSize: 16,
    default: {
      fontFamily:'Inter',
      fontSize:20,
      margin:0,
      color:'#777777'
    },
    editor: {
      body: {
        fontSize:'1.1rem',
        fontWeight:400
      },
      h1: {
        fontSize:'1.6rem',
        fontWeight:700
      },
      h2: {
        fontSize:'1.3rem',
        fontWeight:500,
        textTransform:'uppercase'
      }
    }
  },
  
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          width: 'calc(100%-64px)',
          background: '#ffffffee',
          padding: 32
        },
      },
    },
    MuiBox: {
      variants: [
        {
          props: { variant: 'fixed' },
          style: {
            position:'fixed',
            top:0,
          },
        },
      ]
    },

    MuiGrid: {
      variants: [
        {
          props: { variant: 'fixed' },
          style: {
            position: 'fixed',
            top: 0,
          },
        },
        {
          props: { variant: 'centerWidth' },
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems:'flex-start'
          },
        },
        {
          props: { variant: 'centerHeight' }, 
          style: {
            display: 'flex',
            alignItems: 'center',
          },
        },
        {
          props: { variant: 'center' },
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
        {
          props: { variant: 'space-between' },
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems:'center'
          },
        },
      ],
    },

    MuiButton: {
    },
    
    MuiDrawerHeader: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        title: {
          fontWeight: 'bold',
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '16px 0',
          '& .MuiInputBase-input': {
            backgroundColor: 'white',
          },
        },
      },
    },
  },

  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    footer: 200,
    nav: 100,
    navSticky:50
  },

  breakpoint:{
    xs:380,
    sm:640,
    md:1024,
    lg:1400,
    xl:1920
  }
})






// ALL MUI COMPONENTS
// MuiAppBar
// MuiAvatar
// MuiBackdrop
// MuiBadge
// MuiBottomNavigation
// MuiButton
// MuiButtonBase
// MuiCard
// MuiCardActions
// MuiCardContent
// MuiCardHeader
// MuiCardMedia
// MuiCheckbox
// MuiChip
// MuiCircularProgress
// MuiCollapse
// MuiContainer
// MuiCssBaseline
// MuiDialog
// MuiDialogActions
// MuiDialogContent
// MuiDialogContentText
// MuiDialogTitle
// MuiDivider
// MuiDrawer
// MuiFab
// MuiFilledInput
// MuiFormControl
// MuiFormControlLabel
// MuiFormGroup
// MuiFormHelperText
// MuiFormLabel
// MuiGrid
// MuiIconButton
// MuiInput
// MuiInputAdornment
// MuiInputBase
// MuiInputLabel
// MuiLinearProgress
// MuiLink
// MuiList
// MuiListItem
// MuiListItemAvatar
// MuiListItemIcon
// MuiListItemSecondaryAction
// MuiListItemText
// MuiMenu
// MuiMenuItem
// MuiMobileStepper
// MuiModal
// MuiNativeSelect
// MuiOutlinedInput
// MuiPaper
// MuiPopover
// MuiRadio
// MuiScopedCssBaseline
// MuiSelect
// MuiSkeleton
// MuiSlider
// MuiSnackbar
// MuiSnackbarContent
// MuiSpeedDial
// MuiSpeedDialAction
// MuiSpeedDialIcon
// MuiStep
// MuiStepButton
// MuiStepConnector
// MuiStepContent
// MuiStepIcon
// MuiStepLabel
// MuiStepper
// MuiSvgIcon
// MuiSwitch
// MuiTab
// MuiTable
// MuiTableBody
// MuiTableCell
// MuiTableContainer
// MuiTableFooter
// MuiTableHead
// MuiTablePagination
// MuiTableRow
// MuiTableSortLabel
// MuiTabs
// MuiTextareaAutosize
// MuiTextField
// MuiToggleButton
// MuiToggleButtonGroup
// MuiToolbar
// MuiTooltip
// MuiTouchRipple
// MuiTypography
// MuiUseMediaQuery
// MuiZoom