import { useRoutes } from 'react-router-dom';
import './App.css';
import { guestRoutes, adminRoutes } from './routes';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import { auth } from './firebase';
import { useAuth } from './context/authContext';


function App() {
  const adminContent = useRoutes(adminRoutes);
  const guestContent = useRoutes(guestRoutes);
  const authContext = useAuth();
  return (
    <ThemeProvider theme={theme}>
      {
        auth.currentUser
          ? adminContent
          : guestContent
      }
    </ThemeProvider>
  );
}

export default App;
