import { doc, getDoc } from 'firebase/firestore';
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingScreen from './components/utilities/LoadingScreen';
import { useAuth } from './context/authContext';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const HomePage = Loadable(lazy(() => import('./pages/HomePage')));
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage')));
const ContactPage = Loadable(lazy(() => import('./pages/ContactPage')));
const AboutPage = Loadable(lazy(() => import('./pages/AboutPage')));
const ArticlePage = Loadable(lazy(() => import('./pages/ArticlePage')));

//DASHBOARD
const DashboardPage = Loadable(lazy(() => import('./pages/DashboardPage')));

//UTILITIES
const NotFoundPage = Loadable(lazy(() => import('./pages/NotFoundPage')));


export const adminRoutes = [
  { path: '/', element: <HomePage /> },
  { path: '/dashboard', element: <DashboardPage content="standard" /> },
  { path: '/dashboard/articles/edit/:articleId', element: <DashboardPage content="articles" /> },
  { path: '/dashboard/portfolio', element: <DashboardPage content="portfolio" /> },
  { path: '/dashboard/articles', element: <DashboardPage content="articles" /> },
  { path: '/dashboard/images', element: <DashboardPage content="images" /> },
  { path: '/dashboard/stories', element: <DashboardPage content="stories" /> },
  { path: '/dashboard/settings', element: <DashboardPage content="settings" /> },
  { path: '/dashboard/about', element: <DashboardPage content="about" /> },
  { path: '/dashboard/contact', element: <DashboardPage content="contact" /> },
  { path: '/articles/:articleId', element: <ArticlePage /> },
  { path: '/about', element: <AboutPage /> },
  { path: '/contact', element: <ContactPage /> },
  { path: '/admin', element: <LoginPage /> },
  {
    path: '/*',
    element: <NotFoundPage />
  }
];

export const guestRoutes = [
  { path: '/', element: <HomePage /> },
  { path: '/admin', element: <LoginPage /> },
  { path: '/about', element: <AboutPage /> },
  { path: '/contact', element: <ContactPage /> },
  { path: '/articles/:articleId', element: <ArticlePage /> },
  {
    path: '/*',
    element: <NotFoundPage />
  }
];
