export const Logomark = (props) => {
  const {width, height, color1 = "blue", color2 = "transparent", style, ...other} = props;
  return (
    <div style={style} {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        // x="0px"
        // y="0px"
        width={width}
        height={height}
        viewBox="0 0 96 96"
        xmlSpace="preserve"
      >
        <g>
          <circle fill={color2} cx="48" cy="35.1" r="25.6"/>
          <path fill={color1} d="M48,1.5c-18.3,0-33.2,14.8-33.2,33.2c0,28,26.4,59.8,33.2,59.8c6.8,0,33.2-31.9,33.2-59.8
              C81.2,16.3,66.3,1.5,48,1.5z M56.5,18.8c-2-0.4-4.2-0.7-6.5-0.8v-4.4C52.4,14.2,54.6,16,56.5,18.8z M60.7,32.7H50V21.9
              c3.3,0.2,6.3,0.8,8.9,1.5C59.9,26.2,60.5,29.4,60.7,32.7z M46,17.9c-2.3,0.1-4.5,0.4-6.5,0.8c1.9-2.8,4.1-4.6,6.5-5.2V17.9z
              M46,21.9v10.8H35.3c0.2-3.3,0.8-6.4,1.9-9.2C39.8,22.7,42.8,22.1,46,21.9z M35.3,36.7H46v10.8c-3.3-0.2-6.3-0.8-8.9-1.5
              C36.1,43.1,35.5,39.9,35.3,36.7z M46,51.4v4.4c-2.4-0.6-4.6-2.4-6.5-5.2C41.6,51,43.7,51.3,46,51.4z M50,55.8v-4.4
              c2.3-0.1,4.5-0.4,6.5-0.8C54.6,53.3,52.4,55.1,50,55.8z M50,47.4V36.7h10.7c-0.2,3.3-0.8,6.4-1.9,9.2C56.2,46.6,53.2,47.2,50,47.4z
              M69.3,32.7h-4.6c-0.1-2.6-0.5-5.2-1.2-7.6c2.4,1,4,1.8,4.5,2.1C68.7,28.9,69.1,30.8,69.3,32.7z M61.8,20.2
              c-0.5-1.1-1.1-2.1-1.7-3.1c0,0,0,0,0,0c1.7,1.2,3.2,2.5,4.5,4.1C63.7,20.9,62.8,20.5,61.8,20.2z M35.9,17.1
              C35.9,17.1,35.9,17.1,35.9,17.1c-0.6,1-1.2,2-1.7,3.1c-1,0.3-1.9,0.7-2.8,1C32.7,19.6,34.2,18.2,35.9,17.1z M32.5,25.1
              c-0.6,2.4-1,5-1.2,7.6h-4.6c0.2-1.9,0.6-3.8,1.3-5.5C28.5,26.9,30.1,26,32.5,25.1z M26.7,36.7h4.6c0.1,2.6,0.5,5.2,1.2,7.6
              c-2.4-1-4-1.8-4.5-2.1C27.3,40.4,26.9,38.6,26.7,36.7z M34.2,49.1c0.5,1.1,1.1,2.1,1.7,3.1c0,0,0,0,0,0c-1.7-1.2-3.2-2.5-4.5-4.1
              C32.3,48.5,33.2,48.8,34.2,49.1z M60.1,52.3C60.1,52.3,60.1,52.3,60.1,52.3c0.6-1,1.2-2,1.7-3.1c1-0.3,1.9-0.7,2.8-1
              C63.3,49.7,61.8,51.1,60.1,52.3z M63.5,44.3c0.6-2.4,1-5,1.2-7.6h4.6c-0.2,1.9-0.6,3.8-1.3,5.5C67.5,42.4,65.9,43.3,63.5,44.3z"/>
        </g>
      </svg>
    </div>
  )
}
