import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, onSnapshot, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react"
import { db, storage } from "../firebase";
import { getStorage, ref, listAll, getDownloadURL, uploadBytesResumable, deleteObject } from 'firebase/storage';

// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      ARTICLES
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------
export const createArticle = async (articleObj) => {
  const { payload } = articleObj;
  console.log('payload', payload, articleObj)
  try {
    const collectionRef = collection(db, 'articles');
    const articlePayload = {
      ...payload,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    }

    const newArticle = await addDoc(collectionRef, articlePayload);
    return {status:'success', id: newArticle.id}

  } catch (error) {
    console.log('Error on creating Article', error);
    return {status:'error'}
  }

}







// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------
export const updateArticle = async (articleObj) => {
  const { articleId, payload } = articleObj;
  try {
    const docRef = doc(db, 'articles', articleId);
    const articlePayload = {
      ...payload,
      updatedAt: serverTimestamp()
    }

    const newArticle = await setDoc(docRef, articlePayload, {merge:true});
    return {status:'success'}

  } catch (error) {
    console.log('Error on creating Article', error);
    return {status:'error'}
  }

}





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------
export const deleteArticle = async (articleObj) => {
  const { articleId, payload } = articleObj;
  try {
    const docRef = doc(db, 'deletedArticles', articleId);
    const articlePayload = {
      ...payload,
      deletedAt: serverTimestamp(),
    }

    const newArticle = await setDoc(docRef, articlePayload, {merge:true});

    await deleteDoc(doc(db, 'articles', articleId));
    return {status: "success"}
    
  } catch (error) {
    console.log('Error deleting article item', error)
    return {status: "error"}
  }

}






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------
export const useGetArticles = () => {
  const [articles, setArticles] = useState(null);

  const getArticles = async () => {
    try {
      const collectionRef = collection(db, 'articles');
      // const q = query(collectionRef, where('author', '==', userId));
      onSnapshot(collectionRef, (snapshot) => {
        setArticles(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
    } catch (error) {
      console.log('Error fetching article', error)
    }
  }
  
  useEffect(() => {
    getArticles();
  }, [])

  return articles ? articles : []
}




export const useGetArticle = (articleId) => {
  const [article, setArticle] = useState(null);

  const getArticle = async () => {
    try {
      console.log('trying for article', articleId)
      const docRef = doc(db, 'articles', articleId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setArticle({ ...docSnap.data(), id: docSnap.id });
      } 
      
      // onSnapshot(docRef, (doc) => {
      //   setArticle({ ...doc.data(), id: doc.id });
      //   console.log('article received', doc.data() )
      // })
    } catch (error) {
      console.log('Error fetching article', error)
    }
  }
  
  useEffect(() => {
    if (articleId) {
      getArticle();
    }
  }, [articleId])
  return article ? article : null
}


export const useGetPublishedArticles = () => {
  const [articles, setArticles] = useState(null);

  const getArticles = () => {
    try {
      const collectionRef = collection(db, 'articles');
      const q = query(collectionRef, where('active', '==', true));
      onSnapshot(q, (snapshot) => {
        setArticles(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
    } catch (error) {
      console.log('Error fetching published articles', error)
    }
  }
  
  useEffect(() => {
    getArticles();
  }, [])

  return articles ? articles : []
}




































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      PORTFOLIO
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------
export const createPortfolio = async (portfolioObj) => {
  const { payload } = portfolioObj;
  console.log('payload', payload, portfolioObj)
  try {
    const collectionRef = collection(db, 'portfolio');
    const portfolioPayload = {
      ...payload,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    }

    const newPortfolio = await addDoc(collectionRef, portfolioPayload);
    return {status:'success', id: newPortfolio.id}

  } catch (error) {
    console.log('Error on creating Portfolio', error);
    return {status:'error'}
  }

}







// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------
export const updatePortfolio = async (portfolioObj) => {
  const { portfolioId, payload } = portfolioObj;
  try {
    const docRef = doc(db, 'portfolio', portfolioId);
    const portfolioPayload = {
      ...payload,
      updatedAt: serverTimestamp()
    }

    const newPortfolio = await setDoc(docRef, portfolioPayload, {merge:true});
    return {status:'success'}

  } catch (error) {
    console.log('Error on creating Portfolio', error);
    return {status:'error'}
  }

}





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------
export const deletePortfolio = async (portfolioObj) => {
  const { portfolioId } = portfolioObj;
  try {
    await deleteDoc(doc(db, 'portfolio', portfolioId));
    return {status: "success"}
  } catch (error) {
    console.log('Error deleting portfolio item', error)
    return {status: "error"}
  }

}






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

export const useGetPortfolio = () => {
  const [portfolio, setPortfolio] = useState(null);

  const getPortfolio = () => {
    try {
      const collectionRef = collection(db, 'portfolio');
      // const q = query(collectionRef, where('author', '==', userId));
      onSnapshot(collectionRef, (snapshot) => {
        setPortfolio(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
    } catch (error) {
      console.log('Error fetching portfolio', error)
    }
  }
  
  useEffect(() => {
    getPortfolio();
  }, [])

  return portfolio ? portfolio : []
}

export const useGetActivePortfolio = () => {
  const [portfolio, setPortfolio] = useState(null);

  const getPortfolio = () => {
    try {
      const collectionRef = collection(db, 'portfolio');
      const q = query(collectionRef, where('active', '==', true));
      onSnapshot(q, (snapshot) => {
        setPortfolio(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
    } catch (error) {
      console.log('Error fetching portfolio', error)
    }
  }
  
  useEffect(() => {
    getPortfolio();
  }, [])

  return portfolio ? portfolio : []
}










































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      PAGES
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================







// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------
export const updateAbout = async (aboutObj) => {
  const { payload } = aboutObj;
  try {
    const docRef = doc(db, 'pages', 'about');
    const aboutPayload = {
      ...payload,
      updatedAt: serverTimestamp()
    }

    const newAbout = await setDoc(docRef, aboutPayload, {merge:true});
    return {status:'success'}

  } catch (error) {
    console.log('Error on creating About', error);
    return {status:'error'}
  }

}

export const updateContact = async (contactObj) => {
  const { payload } = contactObj;
  try {
    const docRef = doc(db, 'pages', 'contact');
    const contactPayload = {
      ...payload,
      updatedAt: serverTimestamp()
    }

    const newContact = await setDoc(docRef, contactPayload, {merge:true});
    return {status:'success'}

  } catch (error) {
    console.log('Error on creating Contact', error);
    return {status:'error'}
  }

}

export const updateImages = async (imagesObj) => {
  const { payload } = imagesObj;
  try {
    const docRef = doc(db, 'pages', 'images');
    const imagesPayload = {
      ...payload,
      updatedAt: serverTimestamp()
    }

    const newImages = await setDoc(docRef, imagesPayload, {merge:true});
    return {status:'success'}

  } catch (error) {
    console.log('Error on creating Images', error);
    return {status:'error'}
  }

}






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

export const useGetAbout = () => {
  const [about, setAbout] = useState(null);

  const getAbout = () => {
    try {
      const docRef = doc(db, 'pages', 'about');
      // const q = query(collectionRef, where('author', '==', userId));
      onSnapshot(docRef, (doc) => {
        setAbout({ ...doc.data(), id: doc.id });
      })
    } catch (error) {
      console.log('Error fetching about', error)
    }
  }
  
  useEffect(() => {
    getAbout();
  }, [])

  return about ? about : null
}

export const useGetContact = () => {
  const [contact, setContact] = useState(null);

  const getAbout = () => {
    try {
      const docRef = doc(db, 'pages', 'contact');
      // const q = query(collectionRef, where('author', '==', userId));
      onSnapshot(docRef, (doc) => {
        setContact({ ...doc.data(), id: doc.id });
      })
    } catch (error) {
      console.log('Error fetching contact', error)
    }
  }
  
  useEffect(() => {
    getAbout();
  }, [])

  return contact ? contact : null
}

export const useGetImages = () => {
  const [images, setImages] = useState(null);

  const getImages = () => {
    try {
      const docRef = doc(db, 'pages', 'images');
      // const q = query(collectionRef, where('author', '==', userId));
      onSnapshot(docRef, (doc) => {
        setImages({ ...doc.data(), id: doc.id });
      })
    } catch (error) {
      console.log('Error fetching images', error)
    }
  }
  
  useEffect(() => {
    getImages();
  }, [])

  return images ? images : null
}



































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      SETTINGS
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------
export const createSettings = async (settingsObj) => {
  const { payload } = settingsObj;
  console.log('payload', payload, settingsObj)
  try {
    const collectionRef = collection(db, 'settings');
    const settingsPayload = {
      ...payload,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    }

    const newSettings = await addDoc(collectionRef, settingsPayload);
    return {status:'success', id: newSettings.id}

  } catch (error) {
    console.log('Error on creating Settings', error);
    return {status:'error'}
  }

}







// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------
export const updateAdminSettings = async (settingsObj) => {
  const { payload } = settingsObj;
  try {
    const docRef = doc(db, 'settings', 'admin');
    const settingsPayload = {
      ...payload,
      updatedAt: serverTimestamp()
    }

    const newSettings = await setDoc(docRef, settingsPayload, {merge:true});
    return {status:'success'}

  } catch (error) {
    console.log('Error on creating Settings', error);
    return {status:'error'}
  }

}





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------
export const deleteSettings = async (settingsObj) => {
  const { settingsId } = settingsObj;
  try {
    await deleteDoc(doc(db, 'settings', settingsId));
    return {status: "success"}
  } catch (error) {
    console.log('Error deleting settings item', error)
    return {status: "error"}
  }

}






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

export const useGetAdminSettings = () => {
  const [settings, setSettings] = useState(null);

  const getSettings = () => {
    try {
      const docRef = doc(db, 'settings', 'admin');
      // const q = query(collectionRef, where('author', '==', userId));
      onSnapshot(docRef, (doc) => {
        setSettings({ ...doc.data(), id: doc.id });
      })
    } catch (error) {
      console.log('Error fetching settings', error)
    }
  }
  
  useEffect(() => {
    getSettings();
  }, [])

  return settings ? settings : null
}



































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      STORAGE
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------
export const createImage = async (imageObj) => {
  const { location, setUploadProgress, file } = imageObj;
  const fileName = file.name.replace(' ', '_');
  try{
    // Create the file metadata
    //** @type {any} */
    const metadata = {
      contentType: file.type
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, `${location}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(prog);
        console.log('Upload is ' + prog + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      }, 
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      }, 
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          saveImageToDatabase(downloadURL);
        });
      }
    );

    const saveImageToDatabase = async (url) => {

      try {
        const docRef = doc(db, 'images', 'library');
        const ImagePayload = {
          library: arrayUnion({url: url, fileName:fileName, uploadedAt: new Date()}),
          updatedAt: serverTimestamp()
        }
    
        const newImage = await setDoc(docRef, ImagePayload, {merge:true});
        return {status:'success'}
    
      } catch (error) {
        console.log('Error on adding image', error);
        return {status:'error'}
      }
    
    }

    return {status: 'success'};
    
  } catch (error) {
    console.log('Error uploading picture', error);
    return {status: 'error'};
  }

}







// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------
export const deleteImage = async (imageObj) => {
  const { file, location, libraryItem } = imageObj;
  console.log('file', file);
  console.log('libraryItem', libraryItem);

  const removeImageFromDatabase = async (item) => {

    try {
      const docRef = doc(db, 'images', 'library');
      const ImagePayload = {
        library: arrayRemove(item),
        updatedAt: serverTimestamp()
      }
  
      const newImage = await setDoc(docRef, ImagePayload, {merge:true});
      return {status:'success'}
  
    } catch (error) {
      console.log('Error on removing image', error);
      return {status:'error'}
    }
  
  }

  try {
    const storageRef = ref(storage, `${location}/${file.name}`);
    // Delete the file
    deleteObject(storageRef).then( () => {
      // File deleted successfully
      removeImageFromDatabase(libraryItem);
    }).catch((error) => {
      // Uh-oh, an error occurred!
      console.log('Error in then', error);
    });

    return {status:'success'}

  }
  catch (error) {
    console.log('Error deleting image from storage', error);
    return {status:'error'}
  }


}






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

export const useGetImageList = (props) => {
  const { location, reload } = props;
  const [images, setImages] = useState(null);

  const getImageList = async () => {
    const storage = getStorage();
    // const folderPath = 'image-uploads';
    const storageRef = ref(storage, location);
  
    try {
      const result = await listAll(storageRef);
      
      const imageUrls = await Promise.all(result.items.map(async (imageRef) => {
        try {
          const url = await getDownloadURL(imageRef);
          const stringOne = url.split(`${location}%2F`)[1];
          const name = stringOne.split('?alt')[0];
          // TODO: Display the image on the UI using the URL or store it in some data structure
          return {name: name, url:url};
        } catch (error) {
          // Handle errors in getting download URL
          console.error("Error getting download URL:", error);
          throw error;
        }
      }));
  
      // Now imageUrls contains an array of download URLs for the images in the 'image-uploads' folder
      setImages(imageUrls);
      return imageUrls;
    } catch (error) {
      // Handle errors in listing files
      console.error("Error listing files:", error);
      throw error;
    }
  };
  
    
  useEffect(() => {
    if (location || reload) {
      getImageList();
    }
    
  }, [location, reload])

  return images ? images : null
}

export const useGetImageLibrary = () => {
  const [imageLibrary, setImageLibrary] = useState(null);

  const getImageLibrary = () => {
    try {
      const docRef = doc(db, 'images', 'library');
      // const q = query(collectionRef, where('author', '==', userId));
      onSnapshot(docRef, (doc) => {
        setImageLibrary(doc.data().library);
      })
    } catch (error) {
      console.log('Error fetching settings', error)
    }
  }
  
  useEffect(() => {
    getImageLibrary();
  }, [])

  return imageLibrary ? imageLibrary : null
}





































