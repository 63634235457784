import React from 'react'
import { Logomark } from '../../assets/Logos'
import { useTheme } from '@emotion/react'
import { LinearProgress } from '@mui/material';

const LoadingScreen = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        height:'100vh',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column'
      }}
    >
      <Logomark color1={theme.palette.primary.main} width={200} />
      <LinearProgress style={{height:20, width:200, marginTop:16, borderRadius:40}} />
    </div>
  )
}

export default LoadingScreen