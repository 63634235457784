import React, { createContext, useContext, useEffect, useState } from 'react'
import { onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail, updatePassword } from 'firebase/auth';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import localforage from 'localforage';
import { auth, db } from '../firebase';
import { useGetAdminSettings } from '../hooks/mutations';

const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
}

export const AuthProvider = ({children}) => {
  // PERSISTANT STATE
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState([]);
  const adminSettings = useGetAdminSettings();

  // ====================================================================================
  // LOCAL FORAGE CONFIG FOR ACCOUNT STORE
  // ====================================================================================
  localforage.config({
    name        : 'accountStore',
    storeName   : 'accountStore', // Should be alphanumeric, with underscores.
  });
  const accountStore = localforage.createInstance({name: 'accountStore', storeName: 'accountStore'});

  // GET USER INFO FROM INDEXED DB ON PAGE LOAD / REFRESH / NEW TAB / ETC
  useEffect(() => {
    getUserStores();
  }, [])

  // STORE THE USER STATE INTO THE ACCOUNT STORE IN INDEXED DB
  // WHENEVER THE USER STATE UPDATES WITH USEEFFECT
  useEffect(() => {
    if (user) {
      setUserInfoItem(user);
    }
  }, [user])

  // STORE SETTINGS STATE TO SETTINGS ITEM IN INDEXED DB ACCOUNT STORE
  useEffect(() => {
    if (adminSettings) {
      setSettingsItem(adminSettings);
    }
  }, [adminSettings])

  useEffect(() => {
    if (settings) {
      setSettingsItem(settings);
    }
  }, [settings])


  // SET USER STATE TO INDEXED DB ACCOUNT STORE
  const setUserInfoItem = async (userInfo) => {
    try {
      const userInfoCopy = JSON.parse(JSON.stringify(userInfo)); // make a copy of the userInfo object
      await accountStore.setItem('userInfo', userInfoCopy);
    }

    catch (error) {
      console.log('Error setting user info', error);
    }
  }

  // SET SETTINGS STATE TO INDEXED DB ACCOUNT STORE
  const setSettingsItem = async (settings) => {
    console.log('settings', settings);
    try {
      const settingsCopy = JSON.parse(JSON.stringify(settings)); // make a copy of the settings object
      await accountStore.setItem('settings', settingsCopy);
      // setSettings(settings);
    }

    catch (error) {
      console.log('Error setting project info', error);
    }
  }



  // GET USER INFO FROM ACCOUNT STORE INDEXED DB
  const getUserStores = async () => {
    // Set user state
    try {
      const userInfoValue = await accountStore.getItem('userInfo');
      setUser(userInfoValue);

    } catch (error) {
        // This code runs if there were any errors.
        console.log('Error setting user info state:', error);
    }

    // Set settings state
    try {
      const settingsValue = await accountStore.getItem('settings');
      setSettings(settingsValue);

    } catch (error) {
        // This code runs if there were any errors.
        console.log('Error setting approved project info state:', error);
    }


  }





  // UNSUBSCRIBE FUNCTION
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user) {
        setUser(user)
      } else {
        setUser(false)
        accountStore.clear();
      }
    });

    return () => unsubscribe();
  })

  // START LOGOUT FUNCTION
  const logoutUser = async () => {
      try {
        await signOut(auth);
        setUser(null);
        setSettings([]);
        
      } catch (error) {
        console.log('Error logging user out');
      }
  }

  // START LOGIN FUNCTION
  const loginUser = (email, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        const credential = await signInWithEmailAndPassword(auth, email, password);
        setUser(credential.user);

        resolve(credential.user);
        
      } catch (error) {
        // reject(error);
        console.log('Error logging in user', error);
      }
    })
  }




  return (
    <>
      <authContext.Provider
        value={{
          user,
          setUser,
          settings,
          setSettings,
          adminSettings,
          loginUser,
          logoutUser
        }}
      >
        {children}
      </authContext.Provider>
    </>
  )
}